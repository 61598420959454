import normalize from 'json-api-normalizer'
import moment from 'moment'
import NProgress from 'nprogress'
import ToastService from './toast.service'
import { logout } from '../utils/auth'
import { getCookieFromBrowser, setCookie } from '../utils/session'
import _ from 'lodash'

const snakeCaseKeys = require('snakecase-keys')
const API_URL = process.env.API_URL

let API
let currentAuthToken = getCookieFromBrowser('token')
let originalConfig = null
let originalUrl = null

if (process.env.NODE_ENV === 'development') {
	API = 'http://localhost:3000/api'
} else {
	API = `${API_URL}`
}

const authenticationUrls = [
	// '/me',
	'/login',
	'/logout',
	'/refresh',
	'/verify-email',
	'/request-password-reset',
	'/reset-password',
]

// @ts-ignore: Unreachable code error
function parseDates(key, value) {
	if (
		typeof value === 'string' &&
		moment(value, 'YYYY-MM-DD', true).isValid()
	) {
		return typeof window !== 'undefined'
			? new Date(value)
			: new Date(value).toString()
	}
	return value
}

function CustomException(errors, message?) {
	const error = new Error(message ? message : errors.detail)
	error['errors'] = errors
	return error
}

CustomException.prototype = Object.create(Error.prototype)

export function refreshToken() {
	let url = API + '/refresh'
	let reqConfig = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
			Authorization: `Bearer ${currentAuthToken}`,
			Origin: process.env.BASE_PATH,
		},
	}
	return fetch(url, reqConfig)
}

export function setToken(token) {
	setCookie('token', token)
	currentAuthToken = token
}

export function get(
	url: string,
	params?: any,
	options?: { authenticate?: boolean; token?: string; signal?: AbortSignal }
) {
	return doRequest({
		url,
		params,
		method: 'GET',
		authenticate: (options && options.authenticate) === false ? false : true,
		token: options && options.token ? options.token : null,
		signal: (options && options.signal) || null,
	})
}

export function remove(
	url: string,
	params?: any,
	options?: { authenticate?: boolean; token?: string }
) {
	return doRequest({
		url,
		params,
		method: 'DELETE',
		authenticate: (options && options.authenticate) === false ? false : true,
		token: options && options.token ? options.token : null,
	})
}

export function post(
	url: string,
	params?: any,
	options?: { authenticate?: boolean; token?: string; raw?: boolean }
) {
	return doRequest({
		url,
		params,
		method: 'POST',
		authenticate: options && options.authenticate === false ? false : true,
		token: options && options.token ? options.token : null,
		raw: options?.raw ?? false,
	})
}

export function put(
	url: string,
	params: any,
	options?: { authenticate?: boolean; token?: string }
) {
	return doRequest({
		url,
		params,
		method: 'PUT',
		authenticate: options && options.authenticate === false ? false : true,
		token: options && options.token ? options.token : null,
	})
}

export function download(
	downloadUrl: string,
	params?: any,
	options?: { authenticate?: boolean; token?: string; contentType?: string }
) {
	if (process.browser) {
		NProgress.start()
	}

	let url = API + downloadUrl

	let reqConfig = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
			Origin: process.env.BASE_PATH,
		},
		responseType: options.contentType ? options.contentType : 'text/csv',
	}

	// Add auth header if needed
	if (currentAuthToken && options.authenticate) {
		reqConfig.headers['Authorization'] = `Bearer ${currentAuthToken}`
	}

	const queryString = toQueryString(params)
	url = `${url}?${queryString}`

	return fetch(url, reqConfig)
		.then(async (response) => {
			let result: any
			if (!response.ok && response.status === 401) {
				if (process.browser) {
					if (
						currentAuthToken &&
						currentAuthToken !== 'null' &&
						currentAuthToken !== 'undefined'
					) {
						// cache previous config values
						originalConfig = reqConfig
						originalUrl = url

						// Refresh token
						const tokenResponse: any = await refreshToken()
						const jsonTokenResponse = await tokenResponse.json()

						// Set new token and cookie
						currentAuthToken = jsonTokenResponse.access_token
						setCookie('token', currentAuthToken)

						originalConfig.headers[
							'Authorization'
						] = `Bearer ${currentAuthToken}`
						const newResult = await fetch(originalUrl, originalConfig)
						result = newResult.blob()
					} else {
						return logout(true)
					}
				} else {
					throw new Error('unauthorized')
					return
				}
			} else if (!response.ok && response.status === 403) {
				if (process.browser) {
					ToastService.showError(`You're not allowed to do that`)
					return false
				}
			} else if (!response.ok && response.status === 404) {
				if (process.browser) {
					return false
				}
			} else if (response) {
				result = response.blob()
			}

			return result
		})
		.finally(() => {
			if (process.browser) {
				NProgress.done()
			}
		})
}

export async function doRequest(config: any) {
	if (process.browser) {
		NProgress.start()
	}

	let url = API + config.url
	let reqConfig = {
		method: config.method,
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
			Origin: process.env.BASE_PATH,
		},
	}

	if (config.signal) {
		reqConfig['signal'] = config.signal
	}

	Date.prototype.toJSON = function () {
		return moment(this).format('YYYY-MM-DD')
	}

	// Add auth header if needed
	if (currentAuthToken && config.authenticate) {
		reqConfig.headers['Authorization'] = `Bearer ${currentAuthToken}`
	}

	if (config.params && reqConfig.method === 'GET') {
		const queryString = toQueryString(config.params)
		url = `${url}?${queryString}`
	} else if (config.params) {
		reqConfig['body'] = JSON.stringify(
			snakeCaseKeys(config.params, {
				deep: true,
				exclude: [
					// exclude uuid's
					new RegExp(
						/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
					),
				],
			})
		)
	}

	return fetch(url, reqConfig)
		.then(async (response) => {
			let result: any
			if (
				!response.ok &&
				response.status === 401 &&
				authenticationUrls.indexOf(config.url) <= -1
			) {
				if (process.browser) {
					if (
						currentAuthToken &&
						currentAuthToken !== 'null' &&
						currentAuthToken !== 'undefined'
					) {
						// cache previous config values
						originalConfig = reqConfig
						originalUrl = url

						// Refresh token
						const tokenResponse: any = await refreshToken()
						const jsonTokenResponse = await tokenResponse.json()

						// Set new token and cookie
						currentAuthToken = jsonTokenResponse.access_token
						setCookie('token', currentAuthToken)

						originalConfig.headers[
							'Authorization'
						] = `Bearer ${currentAuthToken}`
						const newResult = await fetch(originalUrl, originalConfig)
						result = newResult.text()
					} else {
						return logout(true)
					}
				} else {
					throw new Error('unauthorized')
					return
				}
			} else if (
				!response.ok &&
				[401, 403, 404, 405, 409, 418, 424].includes(response.status)
			) {
				// Please not that we use 422 for form validation
				// 422 will return back as result for the .error()
				// so can not be caught for general error messages
				// use ApiUnprocessableException in back-end to pass error result to .error()

				if (process.browser) {
					handleErrorResponse(response.status, response.text())
					return false
				}
			} else if (response.ok && response.status === 204) {
				// return true on succesfull delete
				if (process.browser) {
					return true
				}
			} else if (response) {
				// this returns a promise ...just to next then
				result = response.text()
			}

			return result
		})
		.then((response) => {
			if (!response) {
				return response
			}
			const responseJson = JSON.parse(response, parseDates)

			if (responseJson?.exception) {
				throw CustomException(responseJson.exception, responseJson.message)
			}

			if (responseJson.errors) {
				throw CustomException(responseJson.errors, responseJson.message)
			}

			// If authentication url, do not normalize data
			if (authenticationUrls.indexOf(config.url) <= -1 && !config.raw) {
				return Object.assign(
					{},
					normalize(responseJson, { endpoint: config.url })
				)
			} else {
				return Object.assign({}, responseJson)
			}
		})
		.finally(() => {
			if (process.browser) {
				NProgress.done()
			}
		})
}

const handleErrorResponse = (statusCode, result) => {
	result.then((res) => {
		let detail = JSON.parse(res)?.errors?.detail
		let detailStr = typeof detail === 'string' ? detail : null

		switch (statusCode) {
			case 403:
				ToastService.showError({
					title: `You're not allowed to do that`,
					sub_title: detailStr,
				})
				break

			case 404:
				ToastService.showError('404 - Resource not found')
				break

			case 409:
				ToastService.showWarning({
					title: 'Not able to process request',
					sub_title: detailStr,
				})
				break

			case 418:
				ToastService.showSuccess('I am a teapot!')
				break

			default:
				ToastService.showWarning('Something went wrong')
				console.log(detail)
				break
		}
	})
}

export const toQueryString = (params: any) => {
	let encodedStr = ''
	for (let key in params) {
		if (params.hasOwnProperty(key)) {
			if (encodedStr && encodedStr[encodedStr.length - 1] !== '&') {
				encodedStr = encodedStr + '&'
			}
			let value: any = params[key]
			if (value instanceof Array) {
				for (let i in value) {
					if (value.hasOwnProperty(i)) {
						encodedStr =
							encodedStr + key + '=' + encodeURIComponent(value[i]) + '&'
					}
				}
			} else if (typeof value === 'object') {
				for (let innerKey in value) {
					if (value.hasOwnProperty(innerKey)) {
						encodedStr =
							encodedStr +
							key +
							'[' +
							innerKey +
							']=' +
							encodeURIComponent(value[innerKey]) +
							'&'
					}
				}
			} else {
				encodedStr = encodedStr + key + '=' + encodeURIComponent(value)
			}
		}
	}
	if (encodedStr[encodedStr.length - 1] === '&') {
		encodedStr = encodedStr.substr(0, encodedStr.length - 1)
	}
	return encodedStr
}
